@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


/* regular */

@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}


/* light */

@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


/* medium */

@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}



/* Loto */

/* thin */
@font-face {
    font-family: 'Lato';
    src: url('Lato-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

/* light */
@font-face {
    font-family: 'Lato';
    src: url('Lato-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* regular */

@font-face {
    font-family: 'Lato';
    src: url('Lato-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

/* bold */

@font-face {
    font-family: 'Lato';
    src: url('Lato-Bold.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/* medium */

@font-face {
    font-family: 'Lato';
    src: url('Lato-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: 900;
    font-display: swap;
}